@import "primeicons/primeicons.css";
@import "assets/primevue-fix.css";
@import "app/core/css/letpension.css";

/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html,
  body {
    font-family: Montserrat, arial, sans-serif;
    font-size: 13px;
    margin: 0;
    height: 100%;
  }

  h2,
  h3,
  h4,
  h5 {
    margin: 1.2rem 0;
    font-weight: 600;
    line-height: 1.2;
    font-feature-settings: "liga" off;
  }

  h1 {
    font-family: Marcia, georgia, serif;
    font-size: 2.6rem;
    margin-bottom: 1rem;
    line-height: 1.2;
    font-feature-settings: "liga" off;
  }

  h2 {
    font-size: 1.6rem;
    margin-bottom: 0.5rem;
    color: theme(colors.lpblue);
  }

  h3 {
    font-size: 1rem;
    margin-bottom: 0;
  }

  ul {
    list-style-type: square;
    list-style-position: outside;
    padding-inline-start: 19px;
  }

  .lp-menu {
    font-weight: 600;

    .p-panelmenu-panel {
      border: none;
      background-color: transparent;
    }

    .p-panelmenu-submenu-icon {
      color: theme(colors.slate.600);
    }

    .p-panelmenu-header:not(.p-disabled) .p-panelmenu-header-content:hover,
    .p-panelmenu-item:not(.p-disabled) > .p-panelmenu-item-content:hover {
      background-color: transparent;
      opacity: 0.7;
    }

    .p-panelmenu-header-label {
      color: black;
      font-size: 1.2rem;
    }

    .p-panelmenu-item-label {
      font-weight: 400;
      font-size: 1.1rem;
    }

    .p-panelmenu-item-link-active {
      .p-panelmenu-header-label,
      .p-panelmenu-item-label,
      .p-panelmenu-submenu-icon {
        color: theme(colors.lporange);
      }
    }
  }

  .numbers {
    font-feature-settings: "tnum" on;
    letter-spacing: -0.6px;
  }

  .control {
    margin-bottom: 1.2rem;

    label {
      display: block;
      margin-bottom: 0.2rem;
      font-weight: 600;

      + div {
        min-height: 1.5rem;
        padding: 0.5rem .75rem;
        border: 1px solid transparent;
      }

    }

    &.required label:after {
      color: #d00;
      content: "*";
      position: relative;
      left: 4px;
    }

    input,
    textarea {
      display: block;
      width: 100%;
      max-width: 30rem;
    }

    p-select {
      width: 100%;
      max-width: 30rem;
    }


    .error {
      color: theme(colors.lpred);
      font-weight: 400;
    }
  }

  .p-tab[data-p-active="true"] {
    border-bottom: 3px solid theme(colors.lpprimary);
  }

  .lp-table {
    .p-datatable-thead > tr > th.p-datatable-column-sorted {
      background-color: white;
      color: black;
    }
  }

  .detail-section-header {
    font-size: 1.1rem;
    font-weight: 700;
    margin-bottom: 10px;
    color: theme(colors.lpprimary);
  }

  .detail-table {
    td, th {
      padding: 3px 6px;
    }

    th {
      min-width: 100px;
      text-align: left;
    }

    &.bordered {
      td, th {
        border: 1px solid theme(colors.gray.300);
      }
    }
  }

  .detail-label {
    width: 300px;
    font-weight: 500;
    color: black;
  }

}
