.p-panelmenu-header-action {
  display: flex;
  gap: var(--p-panelmenu-item-gap);
  padding: var(--p-panelmenu-item-padding);
  align-items: center;
  user-select: none;
  cursor: pointer;
  position: relative;
  text-decoration: none;
  color: inherit;
}


.p-datatable-sm .p-datatable-tbody > tr > td {
  padding: 0.375rem 0.5rem;
}

.p-datatable-lg .p-datatable-tbody > tr > td {
  padding: 0.9375rem 1.25rem;
}
